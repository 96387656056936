/* Option 2: Import via CSS */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
a:hover {
    color: #0000ff; /* #0ab4ff */
    font-size: 1.1rem;
  }
  .menu-anchor:hover {

    font-weight: bolder;
  }
  
  .menu-label {
    font-size: 13px;
  }
  
  button {
    right: 40px;
  }
  hr {
    margin-top: 0px;
    margin-bottom: 0px;
    opacity: 0.15;
  }
  


.bb{
  border-bottom: 0.1px solid black;
}

.bt{
  border-top: 0.1px solid black;
}

.vheight{
  height: 60px;
  background-color: skyblue;
}

.fr{
  float: right;
}

body {
  font-family: 'Your-Heading-Font', sans-serif;
}
/* Option 2: Import via CSS */
.removestyle{
  text-decoration: none;
  color: black;
  font-size: 14px;
}

.removestyle:hover{
  text-decoration: none;
  color: black;
  background-color: white;
}

.imagesize{
  height: 20px;
  width: 20px;
}

.alertwidth{
  width: 460px !important;
}

.mt{
  margin-top: 120px;
}

.is{
  width: 650px;
  height:720px;
}

.fs{
  font-weight: bold;
  color: brown;
  font-size: 15px;
}

.buttonwidth{
  width: 460px;
}

.buttonwidth1{
  width: 200px;
}

.fs1{
  font-weight: bold;
  
  font-size: 10px;
}

.inputwidth{
  width: 460px !important;
}


.mardown{
  margin-bottom: 40px;
}



.padright{
  padding-right: 0 !important;
}



.col{
  float: 0 1 !important;
}

.dw{
  width: 100%;
  border-radius: 5px;
  height: 100;
}

.bodybackground{
  background-color: burlywood;
}

.bodybackground1 {
  background: burlywood;
}


body
  {
    background-color: #fbfbfb;
  }


/* for dash */

.profilewidth{
  font-size: 20px;

}


.ml{
  margin-left: 20px;
}

#fs{
  font-size: 23px;
  font-style: normal;
 
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}



.fontwt{
  font-weight: bold;
}



/*  For Userrights  */

.padleft{
  padding-left: 300px;
  padding-top: 20px;
}



.colsize{
  width: 20px;
}
.colsize1{
  width: 40px;
}


.readonly{
  color: gray;
}



/*  For userrights */
.dw2{
  width: 260px;
  border-radius: 5px;
}


.marfordrop{
    margin-right: 500px;
}

.searchCard{
  padding: 30px 40px 30px 40px;
}

.tablehead{
  background-color: skyblue;
}

.LabelHeader{
  font-weight: bold;
}


/* for airline*/
.custom-modal-size {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.custom-modal-size .modal-content {
  max-height: 500px;
}




/* Service Master */
.pad{
  padding: 0 !important;
  margin: 0 !important;
}

  .label
  {
    font-weight: bold;
    margin-left: 10px;
    font-size: large;
  }
  .radios[type="radio"]
  {
    border-color: #333;
  
  }
  .radios[type="checkbox"]
  {
    border-color: #333;
  width:100%;
  height: 2vw;
  }
  
  .width
  {
    width: 110px;
  }
  .paddingleft
  {
    padding-left:20px;
  }
  .btnwidth
  {
    width: 6vw;
  }
  .btnwidth2
  {
    width: 7vw;
  }
  .fullScreenCard {
   
    height: 80vh;
    background-color: white; /* Replace with the desired background color */
  }
  .error
  {
    color: red;
  }
  .read-only
  {
    background-color: red; /* Grey background color */
    /* You can also add additional styling properties like padding, border, etc. */
    color: grey;
  }
  #service
  {
    background-color: #f2f2f2;

  }


  /*Import tp*/
  #dw{
    /* margin-top: 8px; */
    width: 100%;
    height: 100%;
    border-radius: 5px;
  
  }


  .inputhead{
    font-weight: bold;
    font-size: 16px;
  }



  
  /*Export PCTM*/
  #dw1{
    margin-top: 8px;
    width: 250px;
    height: 35px;
    border-radius: 5px;
  
  }



  /* Paymeny & bills  */

  .tabspace{
    margin-right: 20px;
    border: 0.1px  solid  gray;
    border-bottom: none;
  }


  .backGround{
      background-color: rgb(181, 166, 166);
  }



  #dw2{
    margin-top: 8px;
    width: 500px;
    height: 35px;
    border-radius: 5px;
  
  }



  .inputSize{
    width: 200px !important;
  }


  /* Import PCTM*/
    #dw3{
    margin-top: 8px;
    width: 300px;
    height: 35px;
    border-radius: 5px;
  
  }

  .dw3{

    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    width: 200px;
    height: 35px;
   border:1px solid #dee2e6;
   border-radius: 0.375rem;
  
  }



  .marginfordrop{
    margin-right: 10px;
  }
  


  #dw4{
    margin-top: 8px;
    width: 160px;
    height: 35px;
    border-radius: 5px;
  
  }



  /*  Export Register */
#dw5{
  margin-top: 8px;
  width: 350px;
  height: 35px;
  border-radius: 5px;
}


/* GST Reports */
#dw6{
  margin-top: 8px;
  width: 280px;
  height: 35px;
  border-radius: 5px;
}






/* Service Master */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
a:hover {
    color: #0000ff; /* #0ab4ff */
    font-size: 1.1rem;
  }
  .menu-anchor:hover {

    font-weight: bolder;
  }
  
  .menu-label {
    font-size: 13px;
  }
  
  button {
    right: 40px;
  }
  hr {
    margin-top: 0px;
    margin-bottom: 0px;
    opacity: 0.15;
  }
  


.bb{
  border-bottom: 0.1px solid black;
}

.bt{
  border-top: 0.1px solid black;
}

.vheight{
  height: 60px;
  background-color: skyblue;
}

.fr{
  float: right;
}


/* Option 2: Import via CSS */
.removestyle{
  text-decoration: none;
  color: black;
  font-size: 14px;
}

.removestyle:hover{
  text-decoration: none;
  color: black;
  background-color: white;
}

.imagesize{
  height: 20px;
  width: 20px;
}

.alertwidth{
  width: 460px !important;
}

.mt{
  margin-top: 120px;
}

.is{
  width: 650px;
  height:720px;
}

.fs{
  font-weight: bold;
  color: brown;
  font-size: 15px;
}

.buttonwidth{
  width: 460px;
}

.fs1{
  font-weight: bold;
  
  font-size: 10px;
}

.inputwidth{
  width: 460px !important;
}

.inputwidth1{
  width: 200px !important;
}


.mardown{
  margin-bottom: 40px;
}



.padright{
  padding-right: 0 !important;
}



.col{
  float: 0 1 !important;
}



.bodybackground{
  background-color: burlywood;
}




/* for dash */

.profilewidth{
  font-size: 20px;

}


.ml{
  margin-left: 20px;
}

#fs{
  font-size: 23px;
  font-style: normal;
 
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}



.fontwt{
  font-weight: bold;
}



/*  For Userrights  */

.padleft{
  padding-left: 300px;
  padding-top: 20px;
}



.colsize{
  width: 20px;
}
.colsize1{
  width: 40px;
}


.readonly{
  color: gray;
}



/*  For userrights */
.dw2{
  width: 260px;
  border-radius: 5px;
}


.marfordrop{
    margin-right: 500px;
}

.searchCard{
  padding: 30px 40px 30px 40px;
}

.tablehead{
  background-color: skyblue;
}

.LabelHeader{
  font-weight: bold;
}


/* for airline*/
.custom-modal-size {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.custom-modal-size .modal-content {
  max-height: 500px;
}




/* Service Master */
.pad{
  padding: 0 !important;
  margin: 0 !important;
}

.label
{
  font-weight: bold;
  margin-left: 10px;
  font-size: large;
}
.radios[type="radio"]
{
  border-color: #333;
  
}
.custom-label.form-check-label
{
  color: black; /* Use the default color */
}
.custom-radio.form-check-input[disabled] {
  opacity: 1; /* This maintains the normal color even when disabled */
  cursor: not-allowed; /* Optional: Change cursor to indicate unclickable */
}
.radios[type="checkbox"]
{
  border-color: #333;
width:100%;
height: 2vw;
}

.width
{
  width: 110px;
}
.paddingleft
{
  padding-left:20px;
}
.btnwidth
{
  width: 6vw;
  transition: none;
}

.fullScreenCard {
 
  height: 80vh;
  background-color: white; /* Replace with the desired background color */
}
.error
{
  color: red;
}
.read-only
{
  background-color: red; /* Grey background color */
  /* You can also add additional styling properties like padding, border, etc. */
  color: grey;
}
#service
{
  background-color: #f2f2f2;

}
input[type="checkbox"]:disabled {
  /* Add your desired styles here to match the original color */
  opacity: 1; /* Adjust the opacity if needed */
  /* Add more styles to maintain the original color */
}
.table-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.table-column {
  flex: 1;
  padding: 8px;
}
.custom-table thead {
 
  height: 60px; /* Adjust the height as needed */
 
  
}

.custom-table thead th
{
  /* Define your custom styles for table header here */
  background-color: #87CEEB;
}

.bold label
{
  font-weight: 400;
}
.font
{
  font-size: 20px;
  font-weight: 400;
}
.containerservices label
{
  font-size: 15px;
  font-weight: 600;
}
.cardback
{
  background-color:rgb(226 232 240)
}
.labeldown label
{
  margin-top: 10px;
}
.singlebtn
{
  height: 3vw;
  width: 9vw;
  transition: none;
}
.matched-row {
  background-color: #f52b2b !important; /* Replace with your desired background color */
}
.error-input {
  border-color: #f52b2b;
}
.custom-datepicker {
  height: 45px;
  width: 100%;
  /* Add other styles as needed */
}
.form-input {
  height: 45px;
  width: 100%; /* Use full width */
}
.btn-disabled {
  /* Define styles to make the link look disabled */
  opacity: 0.5; /* You can adjust this value for desired visual effect */
  cursor: not-allowed;
  pointer-events: none;
}
.label-like-input {
  appearance: none;         /* Remove browser's default appearance */
}
.enhanceinput
{
  width: 10vw;
  height: 40px;
  border: 2px solid #ccc; 
  border-radius: 5px;       
  padding: 8px;            
  font-size: 16px;          
  color: #333;             
  outline: none;           
  transition: border-color 0.3s, box-shadow 0.3s; 
}


/* In your CSS file */

.sidebar-container {
  height: 72%;
  width: 100%;
  overflow-y: auto; /* Enable scrolling within the sidebar if needed */
  position: absolute;

  left: 0;
}

.error-border{
  border: 1px solid red;
}

.css-1097eso{
  background-color: orange !important;
}









/*   Party Entry  */

.pageHead{
  font-size: 20px;
  font-weight: 600;
}


.forlabel{
  font-weight: 600;
  font-size: medium;
}


.inputField{
  font-size: 15px;
  background-color: gray;
  padding: 5px;
}


.allbutton{
  width: 105px;
}



/*  pdf */
/* Style.css */
.custom-header {
  background-color: white !important;  
  color: black !important;
}


.buttton:hover{
  color: white !important;
}


.MenuColor:hover {
  color: black;
}

.menu-item.hovered {
  color: black; /* Change color on hover */
}

/* Define a class for the hover effect */
.menu-item {
  height: 40px;
  color: white;
  transition: color 0.3s; /* Add a smooth color transition */
}

/* Define the hover effect */
.menu-item:hover {
  color: black;
}


.css-dip3t8 {
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 3;
    width: 100% !important;
    background-color: rgb(0 0 0 / 70%) !important;
}

/* .css-1wvake5 */


.bgcolo {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 3;
  background-color: rgb(0 0 0 / 70%) !important;
}


.font1
{
  font-family: 'Your-Data-Font'!important;
}

.css-dip3t8 {
  position: relative;
  height: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100% !important;
  z-index: 3;
  background-color: rgb(249, 249, 249, 0.7);
}
.dropstart .dropdown-toggle::before
{
  color: #fff !important;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000; /* Adjust the color as needed */
}

.close-button:hover {
  color: red; /* Change the color on hover if desired */
}
.error-message
{
 color: red;
}
.redText {
  color: red;
  font-weight: bolder;
  cursor:default;
}
.orangeText
  {
    color: orange;
    font-weight: bolder;
    cursor:default;
  }

  /* Tab Styles */


.nav-tabs .nav-link.active
{
  background-color: hsla(177, 51%, 93%, 0.894) !important;
 
}
.nav-tabs .nav-link.active h6 {
  font-weight: bold;
  color: black; /* Set the font color to black */
}

.total-row td {
  font-weight: bold !important;
  background-color: #fff !important;
  border: 1px solid #9e9d9d !important;
  height: 50px;
}

.refresh-button {
  position: absolute;
  top: 10px;
  right: 100px;
  background: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}
.rounded-image {
  border-radius: 50%;
  width: 200px; /* Example width */
  height: 200px; /* Example height */
  object-fit: cover; /* Ensures the image covers the entire container */
}

.pricing-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 items per row */
  gap: 20px; /* Adjust the gap between items as needed */
  justify-items: center; /* Optional: to center items horizontally */
}

.table-box-wrapper {
  width: 100%; /* Ensures the wrapper takes full available width in its grid cell */
  box-sizing: border-box;
}

/* .priceModal{
  border: 3px solid #dcdcdc;
  border-radius: 10px;
 margin-right: 20px;  
   background-image: linear-gradient(to right, #ffffff, #dcdcdc);


} */

.priceModal {
  border: 3px solid #dcdcdc;
  border-radius: 10px;
  background-image: linear-gradient(to right, #ffffff, #dcdcdc);
  padding: 20px; 
  margin-bottom: 20px; 
  margin-left: 14px; 
  margin-right: 5px; 
  width: 360px; 
}

/* @media (min-width:1200px) {
  .priceModal {
    width: 360px; 
  }
} */
 /* @media (min-width: 992px) {
  .priceModal {
    width: 301px; 
  }
}*/

/* @media (max-width: 500px) {
  .priceModal {
    width: 180px; 
  }
} */

.priceHead{
  font-size: 20px;
  font-weight: 1000;
  color:rgba(255, 0, 0, 0.91);
}

.price{
  font-size: 20px;
  font-weight: 700;
}

/* Add this to your CSS file */
.nav-tabs .nav-link {
  border-radius: 9999px; /* Fully rounded */
  padding: 10px 20px; /* Add some padding */
  transition: all 0.3s ease;
  
}

.nav-tabs .nav-link.active {
  background-color: red !important; /* Primary color */
  color: #fff !important;
}

.nav-tabs .nav-link:hover {
  background-color: red;
  color: #fff !important;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  
  justify-content: center;
  z-index: 1050;
}

.custom-swal-popup {
  background-color: #f0f0f0 !important; /* Change to your desired background color */
  color: #000; /* Change text color if needed */
}

.custom-swal-title {
  color: #000000 !important; /* Set title text color to black */
}

.custom-swal-html {
  color: #000000 !important; /* Set HTML content text color to black */
}

.horizontal-scroll {
  overflow-x: auto;
}
.horizontal-scroll table {
  white-space: nowrap;
}
.horizontal-scroll td {
  vertical-align: top;
}


.padded-div {
  padding: 20px; /* Adjust the value as needed */
  padding-left: 15px;
  padding-right: 15px;
}





.card-slide-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.card-slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 500ms ease-in-out;
}
.card-slide-exit {
  opacity: 1;
  transform: translateX(0);
}
.card-slide-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: all 500ms ease-in-out;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  gap: 15px;
  background: linear-gradient(to right, #ff6a00, #ee0979);
  padding: 10px 20px;
  border-radius: 30px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.styled-button {
  padding: 10px 20px;
  border-radius: 50px;
  background-color: #f1f1f1; /* Default background color */
  border: none;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.styled-button.active {
  background-color: #fa93dc; /* Highlight color */
  color: white; /* White text for the active tab */
  font-weight: bold;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow for emphasis */
}

.styled-button:hover {
  background-color: #e0e0e0; /* Hover effect */
}






/* From Uiverse.io by faizanullah1999 */ 
.primary-button {
  font-family: 'Ropa Sans', sans-serif;
     /* font-family: 'Valorant', sans-serif; */
  color: white;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.05rem;
  border: 1px solid #0E1822;
  padding: 0.8rem 2.1rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 531.28 200'%3E%3Cdefs%3E%3Cstyle%3E .shape %7B fill: %23FF4655 /* fill: %230E1822; */ %7D %3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cpolygon class='shape' points='415.81 200 0 200 115.47 0 531.28 0 415.81 200' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-color: #0E1822;
  background-size: 200%;
  background-position: 200%;
  background-repeat: no-repeat;
  transition: 0.3s ease-in-out;
  transition-property: background-position, border, color;
  position: relative;
  z-index: 1;
 }
 
 .primary-button:hover {
  border: 1px solid #FF4655;
  color: white;
  background-position: 40%;
 }
 
 .primary-button:before {
  content: "";
  position: absolute;
  background-color: #0E1822;
  width: 0.2rem;
  height: 0.2rem;
  top: -1px;
  left: -1px;
  transition: background-color 0.15s ease-in-out;
 }
 
 .primary-button:hover:before {
  background-color: white;
 }
 
 .primary-button:hover:after {
  background-color: white;
 }
 
 .primary-button:after {
  content: "";
  position: absolute;
  background-color: #FF4655;
  width: 0.3rem;
  height: 0.3rem;
  bottom: -1px;
  right: -1px;
  transition: background-color 0.15s ease-in-out;
 }
 
 .button-borders {
  position: relative;
  width: fit-content;
  height: fit-content;
 }
 
 .button-borders:before {
  content: "";
  position: absolute;
  width: calc(100% + 0.5em);
  height: 50%;
  left: -0.3em;
  top: -0.3em;
  border: 1px solid #0E1822;
  border-bottom: 0px;
     /* opacity: 0.3; */
 }
 
 .button-borders:after {
  content: "";
  position: absolute;
  width: calc(100% + 0.5em);
  height: 50%;
  left: -0.3em;
  bottom: -0.3em;
  border: 1px solid #0E1822;
  border-top: 0px;
     /* opacity: 0.3; */
  z-index: 0;
 }
 
 .shape {
  fill: #0E1822;
 }
 
